<template>
  <Layout>
    <div class="container-fluid pt-4">
      <div class="row">
        <div class='col'>
          <h5 class="page-header">
            {{ editingProfile.name }}'s Profile
          </h5>
        </div>
      </div>
      <hr class="dotted-spacer" />
      <!-- Loading spinner -->
      <div
        v-if="$apollo.loading"
        class="position-fixed end-0 w-100 justify-content-center"
        style="background: rgba(255, 255, 255, 0.8); height: 100vh; z-index: 1050"
      >
        <div class="text-center col pt-5 mt-5">
          <BaseSpinner />
          <p class="d-block lead fs-4 mt-5">Loading user's data</p>
        </div>
      </div>
      <div v-else class='row px-5 pt-4'>
        <div class="col col-md-8 col-lg-6 col-xl-4">
          <ValidationObserver ref='form' v-slot="{  }" >
            <form @submit.prevent="updateUser">
              <h5>Profile</h5>
              
              <BaseAlert v-if="alert.type" class="mb-4" :type="alert.type">
                <span slot="title">{{ alert.title }}</span>
                <ul v-if="typeof(alert.message) === 'object'" slot="message">
                  <li v-for="m in alert.message" :key="m">{{ m }}</li>
                </ul>
                <span v-else slot="message">{{ alert.message }}</span>
              </BaseAlert>


              <BaseFormGroup :label="'Name'" >
                <ValidationProvider v-slot="{ errors }" name='Name' rules='required' >
                  <BaseInput v-model="editingProfile.name" type="text" name="name" :error="errors[0]" />
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup :label="'Email'" >
                <ValidationProvider v-slot="{ errors }" name='Email' rules='required|email' >
                  <BaseInput v-model="editingProfile.email" type="text" name="email" :error="errors[0]" />
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup :label="'Team role'" class="text-primary" >
                <BaseMultiselect v-model="editingProfile.role" :options="availableRoles" :single="true" name="role" track-by="name" />
              </BaseFormGroup>

              <BaseFormGroup v-if="editingProfile.role.name == 'TEAM'">
                <ValidationProvider v-slot="{ errors }" name='Self approve' rules="required" >
                  <BaseInput v-model="editingProfile.selfApprove" type="checkbox" name="self-approve" :error="errors[0]"><span>Can self-approve activities</span></BaseInput>
                </ValidationProvider>
              </BaseFormGroup>

              <BaseFormGroup class="mt-5">
                <BaseButton type="submit" :disabled="isLoading" :classes="'btn-success'" >
                  <span>Save</span>
                </BaseButton>

                <BaseButton class="btn-secondary ms-5" :disabled="isLoading" type="button" @click="$router.push({name: 'users'})">
                  <span>Back</span>
                </BaseButton>
              </BaseFormGroup>
            </form>
          </ValidationObserver>
        </div>
      </div>

      <div v-if="user.role.name === 'SYSADMIN'" class="row mt-5">
        <div class="col">
          <div class='card'>
            <div class='card-body bg-light'>
              <h5 class="card-title">SYSADMIN debug</h5>
              <p class='card-text'>
                User:
                <pre><code class="text-break">{{ editingProfile }}</code></pre>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<script>
import Layout from '@layouts/main'
import {
  GetUser,
  LocalGetSelf,
  UpdateUser
} from '@gql/user'
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'
import { find } from 'lodash'

export default {
  page() {
    const name = this.editingProfile ? this.editingProfile.name : 'Profile'

    return {
      title: name,
      meta: [
        {
          name: 'description',
          content: `Edit ${name}.`,
        },
      ],
    }
  },
  components: { Layout, ValidationProvider, ValidationObserver },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      alert: {},
      editingProfile: {
        selfApprove: false,
        role: { name: 'USER', label: 'User' }
      },
      availableRoles: [
        { name: 'ADMIN', label: 'Admin' },
        { name: 'TEAM', label: 'Team member' },
        { name: 'VIEWER', label: 'Viewer' },
        { name: 'USER', label: 'User' },
      ],
      unavailableRoles: [
        { name: 'SYSADMIN', label: 'System Admin' }
      ],
      originalRole: null,
    }
  },
  apollo: {
    user: {
      query: LocalGetSelf,
      update(data) {
        if(!data.user.isAdminUser) { this.$router.push({ name: '403', params: { resource: 'user.edit' }}) }
        return data.user
      }
    },
    editingProfile: {
      query: GetUser,
      fetchPolicy: "no-cache",
      variables() {
        return { id: this.id }
      },
      update(data) { 
        const user = data.user
        user.role.label = find([...this.unavailableRoles, ...this.availableRoles], { name: user.role.name }).label
        return user
      },
      result({ data: { user } }) {
        if (!user) {
          this.$router.push({ name: '404', params: { resource: 'User' } })

          // If the user logged is trying to edit a sysadmin and is not themselves a sysadmin, forbid it
          if(this.user.role !== 'SYSADMIN' && user.role === 'SYSADMIN') {
            this.$router.push({ name: '403', params: { resource: 'User ' + this.id } })
          }

          user.role = find(this.availableRoles, { id: user.role })
          this.originalRole = user.role
        }
      },
    },
  },
  methods: {
    async updateUser() {
      this.isLoading = true
      this.alert = {}

      // Validate the form
      if(!await this.$refs.form.validate()) {
        this.isLoading = false
        this.alert.type = 'error'
        this.alert.title = 'Error'
        this.alert.message = 'Please check the form below for errors'
        return false
      }
      // Validation good, reset
      this.$refs.form.reset()

      const canSelfApprove = ['ADMIN', 'SYSADMIN'].includes(this.editingProfile.role.name) ? true 
        : (['VIEWER'].includes(this.editingProfile.role.name) ? false : this.editingProfile.selfApprove)

      this.$apollo.mutate({
        mutation: UpdateUser,
        variables: {
          id: this.id,
          role: this.originalRole === 'SYSADMIN' ? this.originalRole : this.editingProfile.role.name,
          selfApprove: canSelfApprove || false,
          name: this.editingProfile.name || undefined,
        },
        update: (store, { data: { updateUser }}) => {},
        error (err) {
          this.$log.error(err)
          this.alert.type = 'error'
          this.alert.title = 'There was a problem'
          this.alert.message = err
        },
      }).then(data => {
        this.alert.type = 'success'
        this.alert.title = 'Success'
        this.alert.message = 'User updated'
        this.isLoading = false
        this.$refs.form.reset()
        // Redirect
        setTimeout(() => { this.$router.push({name: 'users'}) }, 1000)
      }).catch((err) => {
        // Error
        this.alert.type = 'error'
        this.alert.title = 'There was a problem'
        this.alert.message = err
      })
    }
  }
}
</script>